
import { Prop, Watch } from "vue-property-decorator";
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Options } from "vue-class-component";
import { checkDBPath, PathType } from "@/scripts-core/util/TextHelper";
import { emptyPlan, ManagedUser, NDISPlan, PlanFunding } from "possibleme-db";
import AddFunding from "@/components/possibleme/helper/AddFunding.vue" 
import * as DB from "@/scripts-core/CloudDB"
import * as DateHelp from "possibleme-db/lib/util/DateHelper"
import CloudObject, { ObjectHelper } from "@/scripts-core/cloud/CloudObject";
import PhoenixSubmit, { LoadHandler } from "@/components/phoenix/PhoenixSubmit.vue"
import { volUserManagedUser } from "possibleme-db/lib/Paths";
import { containsPlanConflict } from "@/scripts-possibleme/client/VClientUtil"
import { getPlans, getUsersFirstname, getUsersFullname } from "@/scripts-possibleme/client/PossibleClient"
import FundingItem from "../helper/FundingItem.vue";


@Options({
    components: {
        AddFunding,
        PhoenixSubmit,
        FundingItem
    },
})
export default class PlanDialogNext extends FireComponent {
    usersFullname = "Myself"

    @Prop()
    dbpath! : string
    existing : CloudObject<NDISPlan> | null = null

    @Prop()
    onPlan! : (plan : NDISPlan)=>void

    pathType : PathType = "collection"

    formPlanName = ""
    formPlanDesc = ""
    formStartDate = DateHelp.toInputDate((new Date()).getTime())
    formEndDate = DateHelp.toInputDate((DateHelp.addDays(new Date(), 1)).getTime())

    formFunding : {[key : string]: PlanFunding} = {

    }

    formFile : any = null
    formFilename2 : string | null = null
    formFileDisplayDEP = ""

    selectUser : CloudObject<ManagedUser> | null = null

    errors : any  = {
      name : "",
      start : ""
    }
    submitHandler! : LoadHandler

    inpUsers : CloudObject<ManagedUser>[] = []

    onSetFundingAmount(event : any){
        return
    }

 
    onSubmitHandler(handler : LoadHandler){
        this.submitHandler = handler;
    }
    /* Convert BigInt */
    convertFundMap(){
        for(let keyFund in this.formFunding){
            const numInit = this.formFunding[keyFund].init as any;
            const numAmount = this.formFunding[keyFund].amount as any

            this.formFunding[keyFund].init = numInit.toString();
            this.formFunding[keyFund].amount = numAmount.toString();
      }
    }

    async onUserReady(uid: string) {
        this.usersFullname = getUsersFullname()
        try {
            this.inpUsers = await DB.listDb<ManagedUser>(this, volUserManagedUser(uid));
            
        }catch(e) { console.error(e) }


        if(this.pathType == "document"){
            this.existing = await DB.document<NDISPlan>(this, this.dbpath);
            if(this.existing == null)
                return;
            const val = this.existing.copy();

            this.onBindToView(val);
      }
    }

    /* Checks whether the Fuding Key is an existing item in the plan */
    existingFunding(keyFund : string) : boolean {
        if(!this.existing)
            return false;
        const prevFund = this.existing.value(this).funding;
        return keyFund in prevFund
    }
   
    resetErrors(){
        for(let key in this.errors)
            this.errors[key] = "";
    }
    onDeleteFunding(keyFund : string | number){
        delete this.formFunding[keyFund]
    }

    onAddFunding(key : string, funding : PlanFunding){
        this.submitHandler(false, '');
        if(this.formFunding[key]){
            this.submitHandler(false,"Funding category already exists")
            return;
        }
        this.formFunding[key] = funding
    }

    mounted(){
        this.pathType = checkDBPath(this.dbpath);
    }


    /* User changes the target files */
    onFile(target : any){
        if(target.files.length == 0){
            this.formFilename2 = null
            this.formFile = null

        }
        else {
            this.formFile = target.files[0];
            this.formFilename2 = target.files[0].name;
        }
    }

    /* Sets the view based on the model */
    onBindToView(plan : NDISPlan){
        this.formPlanName = plan.name;
        this.formPlanDesc = plan.description
        this.formStartDate = DateHelp.toInputDate(plan.dateStart);
        this.formEndDate = DateHelp.toInputDate(plan.dateEnd);
        this.formFilename2 = plan.filename;
        this.formFunding =  {
            ...plan.funding
        }
    
        if(plan.managedUser != null) 
            this.selectUser = ObjectHelper.resolveId(plan.managedUser, this.inpUsers);
    }

    onValidateForm(plan : NDISPlan) : string | null {
        if(this.formPlanName.trim() == ""){
            const err = "NDIS Plan must have a name.";
            this.errors.name = err;
            return err;
        }
        if(this.formStartDate == null)
            return "Start date not set."
        if(this.formEndDate == null)
            return "End date not set."
      
        const planStart = DateHelp.fromInputDate(this.formStartDate);
        const planEnd =   DateHelp.fromInputDate(this.formEndDate);
        if(planStart == null || planEnd == null)
            return "Unable to convert dates."
    
        if(planStart.getTime() > planEnd.getTime())
            return "NDIS plan's start date must be before ending date."
      
        const afterFundsize = Object.keys(this.formFunding).length + Object.keys(plan.funding).length
        if(afterFundsize == 0)
            return "NDIS plan must include at least 1 funding category."

        //Check Plan Conflict
        if(containsPlanConflict(this, DateHelp.toInterval(planStart, planEnd), this.existing, this.selectUser, getPlans()))
            return "NDIS plan period conflicts with an existing plan. Please use another user or different start or end date."



        return null;
    }
   
   /*Submit to Database */
    async onSubmit(plan : NDISPlan){
        try {
            if(this.formFile != null && this.formFilename2){
                await DB.saveUserFile(this.formFile, this.formFilename2);
            }
        }
        catch(e){
            console.error(e);
            this.formFilename2 = null
        }

        plan.filename = this.formFilename2
        plan.fileDisplay = "Deprecated::"
        plan.name = this.formPlanName;
        plan.description = this.formPlanDesc;

        plan.dateStart = DateHelp.fromInputDate(this.formStartDate)?.getTime() ?? Date.now();
        const convertEnd = DateHelp.fromInputDate(this.formEndDate) ?? new Date();
        plan.dateEnd = DateHelp.setEndOfDay(convertEnd).getTime();

        plan.managedUser = this.selectUser?.id?.() ?? null
        

        /** COnvert Map*/
        this.convertFundMap();

        plan.funding = this.formFunding
        this.onPlan(plan);
    }
  
    /* When User Submits */
    async onDone(handle : LoadHandler) {
        handle(true);
        this.resetErrors();

        const localType : PathType = this.pathType ?? "collection";

        switch(localType){
            case "collection": {
                const newPlan = emptyPlan();
                const error = this.onValidateForm(newPlan);
                if(error)
                    return handle(false, error);
               
                await this.onSubmit(newPlan); 
                await DB.pushDb(this, this.dbpath, newPlan);
               
            }break;

            case "document" : {
                if(this.existing == null)
                    return handle(false, "Document is empty")
                const error = this.onValidateForm(this.existing.value(this))
                if(error)
                    return handle(false, error)
                await this.onSubmit(this.existing.value(this));
            }break;
        }
        handle(false);
    }
    componentClass(): string {
        return "plan-dialog";
    }
}
