

import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { PlanFunding } from "possibleme-db";
import { Prop } from "vue-property-decorator";
import MoneyInput from "../helper/MoneyInput.vue"

@Options({
    components : {
        MoneyInput
    },
    emits : ["amount", "clear", "set"]
})
export default class FundingItem extends FireComponent {

    @Prop()
    fundingItem! : PlanFunding
    @Prop()
    fundingIndex! : string

    @Prop()
    delegateExistingFuding! : (fudingKey : string) => boolean

    @Prop()
    delegateDeleteFunding! : (fundKey : string | number) => void

    componentClass(): string {
        return "FundingItem"
    }
    onDeleteFunding(){
        this.delegateDeleteFunding(this.fundingIndex)
        return
    }
    existingFunding(): boolean {
        return this.delegateExistingFuding(this.fundingIndex)
    }
    onSetAmount(valueAmount : string) {
        this.fundingItem.amount = valueAmount
    }
    onSetSpent(valueSpent : string) {
        this.fundingItem.init = valueSpent
    }
}
