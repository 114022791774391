<template>
    <div class='module-model_funding'>
        <table>
            <tr>
                <th class=''>Category</th>
                <th class=''>Funded Amount</th>
            </tr>
            <tr v-for="(item, index) in sortSet" :key="`funding:${index}`">
                <td class='flex'>
                    <div class='material-icons' >pie_chart</div>
                    <div class='category-name'>{{ item.name }}</div> 
                </td>
                <td class='money'>{{displayAmount(item.amount) ?? "ERR"}}</td>
            </tr>
        </table>
    </div>
</template>

 
<script lang="ts">

import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { PlanFunding } from "possibleme-db";
import { Prop, Watch } from "vue-property-decorator"
import { displayMoney } from "possibleme-db/lib/util/BigNum"

export default class ModelSupport extends FireComponent {
    @Prop()
    funding! :  {[key: string]: PlanFunding};

    sortSet : {[key: string]: PlanFunding} = {};

    displayAmount = displayMoney

    @Watch("funding")
    update(){
        this.sortSet = {}
        const list = Object.keys(this.funding).sort((a, b)=> {
            const aFund = this.funding[a];
            const bFund = this.funding[b];

            const great = aFund.name > bFund.name;
            if(great)
                return 1;
            return -1;
        });
        list.forEach(key => this.sortSet[key] = this.funding[key])
    }
    
    mounted(){
        this.update()
    }
   

    componentClass = () => "possible-model-funding"
    onUserReady(): void {
        return;
    }
    
}
</script>

<style scoped>
.money {
    padding-left: .5rem;
}
.material-icons {
    font-size: 1rem;
    margin-top: auto;
    margin-bottom: auto 
}
.category-name {
    margin-left: .5rem;
}

@media (max-width: 500px) {
    .category-name {
        min-width: 150px;
    }
}

@media(min-width: 800px) {
    .category-name {
        min-width: 400px;
    }
}

    
</style>
    