
import { subscribeCurrentPlan } from "@/scripts-possibleme/client/PossibleClient"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Options } from "vue-class-component"

const EMPTY_PLAN = "No Avaliable Plan"

@Options({
    components : {

    }
})
export default class PlanSelect extends FireComponent {
    planName = EMPTY_PLAN

    onUserReady(uid: string | null) : void {
        subscribeCurrentPlan(this.getContext(), plan =>{
            if(plan == null)
                this.planName = EMPTY_PLAN
            else
                this.planName = plan.value(this).name

        })
     }
    componentClass(): string {
        return "select-plan"
    }
 
}
