<template>
    <div class=' compose content-plan'>
        <div @click="click">
            <div class='content-plan-title'>
                <h3 class='pl-4 pr-4 plan-title auto--margin'>{{plan.object.name}} </h3> 
               
                <h4 class='auto--margin'>{{getDays()}}</h4>
                
            </div>
            <div class='pl-4 pr-4 shift-down__shrink'>
                <div class='compose-flex content-table'>
                    <div class='auto--margin material-icons'>event</div>
                    <div>{{dateHelper.displayDate(plan.object.dateStart)}}</div>
                    <div>-</div>
                    <div>{{dateHelper.displayDate(plan.object.dateEnd)}}</div>
                </div> 
                <div class='content-table'>
                    <div class='auto--margin material-icons'>notes</div>
                    <div>{{plan.object.description}}</div>
                    </div>
                <div class='content-table'>
                    <div class='auto--margin material-icons'>cloud_upload</div>
                    <div @click="onFile" class='underline text-blue-500 cursor-pointer'> {{getFilename()}}</div>
                </div>
                <ModelFunding :funding="plan.object.funding">
                </ModelFunding>
            </div>
            
            
        </div>
        <div class='indent flex actions mt-2 mobile-flex gap-2'>
            <MeButton :labelText="'Edit'" @click="onEdit"></MeButton>
            <MeButton :labelText="'Select'"  @click="onSelect"></MeButton>
            <MeButton :labelText="'Plan Report'"  @click="onReportPlan"></MeButton>
            <MeButton :labelText="'Budget Report'" @click="onReportBudget"></MeButton>

        </div>
    </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import ModelFunding from "@/components/possibleme/model/ModelFunding.vue"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { NDISPlan } from "possibleme-db";
import { Prop } from "vue-property-decorator"
import * as DateHelp from "possibleme-db/lib/util/DateHelper"
import {selectCustomPlan} from "@/scripts-possibleme/client/PossibleClient"
import {downloadUserURL} from "@/scripts-core/CloudDB"
import MeButton from "../helper/MeButton.vue"


export type OnEditPlan = (plan : CloudObject<NDISPlan>)=>void

@Options({
    components :{
        ModelFunding,
        MeButton
    }
})

export default class ModelProvider extends FireComponent {
    @Prop()
    plan! : CloudObject<NDISPlan>
    dateHelper = DateHelp

    @Prop()
    edit! : OnEditPlan

   

    componentClass = () => "possible-model-plan"
    onUserReady(uid: string|null): void {
        return;
    }
    onSelect(){
        selectCustomPlan(this.plan);
        this.sendAlert("New Plan Selected", `You are now viewing NDIS Plan:\n'${this.plan.value(this).name}'`)
    
        this.$router.push( { path : this.$route.path , query : {
            select :  this.plan.id()
        } })
    }
    async onFile(){
        const filename = this.plan.value(this).filename;

        try {
            if(filename){
                const fileUrl = await downloadUserURL(filename);
                window.open(fileUrl);
     
            }
        }catch(e){
            console.error(e);
        }
    }
    onReportPlan(){
        const path = this.$router.resolve({
            path : "/report/plan",
            query : {
                planId : this.plan.id()
            }
        })
        window.open(path.href, '_blank');
    }
    onReportBudget(){
        const path = this.$router.resolve({
            path : "/report/budget",
            query : {
                planId : this.plan.id()
            }
        })
        window.open(path.href, '_blank');
    }

    onEdit(){
        this.edit(this.plan);
    }

    click(){
        return;
    }
    getFilename(){
        let filename = this.plan.value(this).filename
        if(filename === "" || filename === undefined || filename === null)
            filename = "n/a"
        return filename;
    }
    getDays(){

        const now = Date.now();
        const end = this.plan.value(this).dateEnd;
        const start = this.plan.value(this).dateStart;
        if(end < now)
            return ""
        const delta = Math.floor( (end - now) /  86400000);
        return `${delta} Days Left`
    }
}
</script>

<style scoped>
.actions {
    justify-content: left;
}

.plan-filename {
    text-decoration: underline;
    color:blue;
}
.plan-filename:hover {
    color:red;
    cursor: pointer;
}


.plan-title{
    
    min-width: 300px;
}
.content-plan-title{
    /*color: var(--color--accent);*/
    color: white;
    background: var(--color--accent__70);
    
    display: flex;
    padding-bottom: .2rem;
    padding-top: .2rem;
    
}
.content-table {
    display: flex;
    gap: 1rem;
}
.content-plan {
    padding-bottom: var(--stm--margin);
}
    

</style>