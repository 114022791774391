<template>
    <div class='bg-white'>
        <PlanBanner>
            <template v-slot:body>
                <h1>NDIS Plans</h1>
                <MeAction  @click="onAddPlan" :labelText="'Add Plan'"></MeAction>
                <MeSearch @search="onSearch" :hint="'Search for plan name or description.'"></MeSearch>
            </template>
        </PlanBanner>
        
        <div class='compose main-frame'>
            <div class='pl-4 pr-4'>
                
            </div>
            <div class='mt-4'>
                <h2 class='pl-4 pr-4 ndis-head' >Current NDIS Plans</h2>
                <ModelPlan v-for="(plan,index) in planCurrent" :key="`plan-up-curr:${index}`" :plan="plan" :edit="onEditPlan" >
                </ModelPlan>
                <h2 class='pl-4 pr-4 ndis-head' >Upcoming NDIS Plans</h2>
                <ModelPlan v-for="(plan,index) in planUpcoming" :key="`plan-up:${index}`" :plan="plan" :edit="onEditPlan" >
                </ModelPlan>
                <h2 class='pl-4 pr-4 ndis-head'>Past Plans</h2>
                <ModelPlan v-for="(plan,index) in planOld" :key="`plan-old:${index}`" :plan="plan" :edit="onEditPlan" >
                </ModelPlan>
                <PhoenixPopup :title="`NDIS Plan`" @toggler="onToggler">
                    <template v-slot:body>
                        <PlanDialogNext :onPlan="onPlanSubmit" :dbpath="planUrl" >
                        </PlanDialogNext>
                    </template>
                </PhoenixPopup>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import * as VClient from "@/scripts-possibleme/client/PossibleClient"
import { NDISPlan } from "possibleme-db";
import ModelPlan from "@/components/possibleme/model/ModelPlan.vue"
import PhoenixPopup, { PopupToggler } from "@/components/phoenix/ObjectPopup.vue"
import PlanDialogNext from "@/components/possibleme/dialog/PlanDialogNext.vue"
import PlanSelect from "@/components/possibleme/PlanSelect.vue"
import { bucketPlans } from "@/scripts-possibleme/client/VClientUtil"
import PlanBanner from "@/components/possibleme/PlanBanner.vue"
import MeAction from "../../components/possibleme/helper/MeAction.vue";
import MeSearch from "../../components/possibleme/helper/MeSearch.vue";
import {matchPlan} from "possibleme-db/lib/util/SearchUtil"
import { logEventPageView, PAGE_PLAN } from "@/possibleme/PossibleAnalytics";


@Options({
    components : {
        ModelPlan,
        PhoenixPopup,
        PlanDialogNext,
        PlanSelect,
        PlanBanner,
        MeAction,
        MeSearch
    }
})
export default class VuePlan extends FireComponent  {
    newPlanPath! : string

    planUrl : null | string = null;

    currentPlan : CloudObject<NDISPlan> | null = null;
    planUpcoming :  CloudObject<NDISPlan>[] = [];
    planCurrent :  CloudObject<NDISPlan>[] = [];
    planOld :       CloudObject<NDISPlan>[] = [];

    allPlans : CloudObject<NDISPlan>[] = []

    togger? : PopupToggler

    mounted() {
        logEventPageView(PAGE_PLAN)
    }

    clkPlan(plan : any){
        return;
    }

    componentClass = ()=> "possiblevue-plan"

    setPlanView(plans : CloudObject<NDISPlan>[]){
        const [current, upcoming, old] = bucketPlans(this, plans)
        
        this.planCurrent = current
        this.planUpcoming = upcoming
        this.planOld = old
    }

    onUserReady(uid: string|null) : void {

        this.newPlanPath = `users/${uid}/plans`

        VClient.subscribeCurrentPlan(this.getContext(), (plan =>{
            this.currentPlan = plan;
        }))
    
        VClient.subscribePlans(this.getContext(), sub => {
           if(!sub)
                return;
            this.allPlans = sub;
            this.setPlanView(this.allPlans);
            
        })
    }
    onSearch(searchText : string){
        searchText = searchText.trim();
        if(searchText === "")
            return this.setPlanView(this.allPlans);
        const filter = this.allPlans.filter(item => matchPlan(searchText, item.value(this)))
        return this.setPlanView(filter);
    }

    onEditPlan(plan : CloudObject<NDISPlan>){
        this.planUrl = plan.path();
        this.togger?.(true);
    }

    onAddPlan(){
        this.planUrl = this.newPlanPath;
        this.togger?.(true);
    }
    onToggler(toggler: PopupToggler){
        this.togger = toggler;
    }

    onPlanSubmit(plan : NDISPlan){
        this.togger?.(false, "done")
    }
    
}
</script>

<style scoped>
.ndis-head {
    background: purple;
    color: white;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: 0;
}
</style>