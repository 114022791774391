<template>
    <div class='content-funding'>
        <div class='compose--flex'>
            <h5 class='fund-title auto--margin'>{{ fundingItem.name }}</h5>
            <button class='material-icons auto--margin fund-clear' @click="onDeleteFunding" v-if="!existingFunding()" >clear</button>
        </div>
        <table class='widget-table'>
            <tr>
                <td class='fund-cell'>Amount</td>
                <td>
                    <MoneyInput @amount="onSetAmount" :initAmount="fundingItem.amount"/>
                </td>
            </tr>
            <tr>
                <td class='fund-cell'>Spent</td>
                <td>
                    <MoneyInput @amount="onSetSpent"  :initAmount="fundingItem.init" />
                </td>
            </tr>
        </table>  
    </div>
</template>

<script lang="ts">

import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { PlanFunding } from "possibleme-db";
import { Prop } from "vue-property-decorator";
import MoneyInput from "../helper/MoneyInput.vue"

@Options({
    components : {
        MoneyInput
    },
    emits : ["amount", "clear", "set"]
})
export default class FundingItem extends FireComponent {

    @Prop()
    fundingItem! : PlanFunding
    @Prop()
    fundingIndex! : string

    @Prop()
    delegateExistingFuding! : (fudingKey : string) => boolean

    @Prop()
    delegateDeleteFunding! : (fundKey : string | number) => void

    componentClass(): string {
        return "FundingItem"
    }
    onDeleteFunding(){
        this.delegateDeleteFunding(this.fundingIndex)
        return
    }
    existingFunding(): boolean {
        return this.delegateExistingFuding(this.fundingIndex)
    }
    onSetAmount(valueAmount : string) {
        this.fundingItem.amount = valueAmount
    }
    onSetSpent(valueSpent : string) {
        this.fundingItem.init = valueSpent
    }
}
</script>

<style scoped>
.content-funding {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.fund-clear {
    margin-left: 1rem;
    border-radius: .3rem;
    color: white;
    background-color: tomato;
}
.fund-clear:hover {
    color: black;
    background-color: red;
}
.fund-title{
    display: inline-block;
    padding: .2rem;
    background-color: var(--color--accent) ;
    border-radius: .3rem;
    color:white;
}
</style>

