<template>
    <div class='module-add_funding'>
        <h4>Add Funding Category</h4>
        
        <select class='fill space select-category' v-model="selectCategory"  name="category" id="category">
            <option value="null" disabled selected>Add Budget Category</option>
            <option v-for="(budget,index) in listCategory" :key="`provider:${index}`" :value="budget">{{ budget.display }}</option>
          </select>
        <div class='category' v-if="isVisible">
            <input class='category-name' v-model="formCategory"  type="text" placeholder="Category Name"/> 
            <MeButton @click="onCategory" :labelText="'Add Category'"></MeButton>
        </div>
        <MoneyInput :header="'Amount'" @amount="onAmtBudget" @clear="clearBudget" />
        <MoneyInput :header="'Previous Amount'" @amount="onAmtPrev" @clear="clearPrev"/>

          
        <div v-if="error != ''" class='error'>{{error}}</div>
        <MeAction :full="true" @click="onSubmit" :labelText="'Add Funding Item'"></MeAction>
  </div>
</template>

<script lang="ts">
import {Options} from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { Category, PlanFunding } from "possibleme-db";
import { uuid } from "vue-uuid"
import { isDecimal } from "possibleme-db/lib/Valid"
import { BUDGET_CATEGORIES } from "possibleme-db/lib/model/Budget"
import MoneyInput from "@/components/possibleme/helper/MoneyInput.vue"
import MeButton from "./MeButton.vue"
import MeAction from "./MeAction.vue"


const Custom : Category = {
    display : "(Add Custom)",
    idRef : "custom"
}



type OnPlanFunding = (key : string, funding : PlanFunding) => void

@Options({
  components :{
      MoneyInput,
      MeButton,
      MeAction
  },
  emits :["planfunding-item"]
})

export default class AddFunding extends FireComponent {
    error = ''
    isVisible =  false;

    formCategory = ""
    formBudget = ""
    formInit = ""

    @Prop()
    onFunding!: OnPlanFunding

    listCategory : Category[] = [] ;//= BUDGET_CATEGORIES

    
    selectCategory  : Category | null = null

    clPrev : any
    clBudget  : any

    mounted(){
        BUDGET_CATEGORIES.forEach(item => this.listCategory.push(item))
        this.listCategory.push(Custom);
    }
    

    onAmtPrev = (arg : string) => this.formInit = arg

    onAmtBudget = (arg : string) => this.formBudget = arg

    clearPrev(handle : any){
        this.clPrev = handle
    }
    clearBudget(handle : any){ 
        this.clBudget = handle
    }


    fixDecimal(event : any ){
        const num = event.target.value as string
        const fixed =  Number(num);//.toFixed(2);
        const split = num.split(".")
    
        if(split.length > 0 &&  split[1].length > 2) 
            event.target.value = Number(num).toFixed(2);
    }

    
 
    onCategory(){
        const newCat : Category = {
            display : this.formCategory,
            idRef : uuid.v4()
        }
     
        this.listCategory.push(newCat);
        this.selectCategory = newCat;
        this.isVisible = false;
        this.formCategory = "";
    }

    onSubmit(){
        if(this.selectCategory == null){
            this.error = "Select a valid category"
            return;
        }
        if(this.selectCategory.idRef == "custom"){
            this.error = "Please name the custom category"
            return;
        }
        if(!isDecimal(this.formBudget).valid){
            this.error = "Select a valid budget amount"
            return;
        }
        if(!isDecimal(this.formInit).valid){
            this.error="Select a valid previous amount"
            return;
        }

        const funding : PlanFunding = {
            amount : this.formBudget.toString(),
            init :  this.formInit.toString(),
            name : this.selectCategory.display
        }
        //const result = validPlanFunding(funding);
        this.onFunding(this.selectCategory.idRef, funding);
        this.selectCategory = null;
        this.formBudget = ""
        this.formInit = ""
        this.error = ''

        this.clBudget();
        this.clPrev();
        
    }

    @Watch("selectCategory")
    onChanged(){
        if(this.selectCategory?.idRef == "custom")
            this.isVisible = true;
        else {
            this.isVisible = false;
        }
    }

    onUserReady(uid: string|null): void {
        return;
    }
    componentClass(): string {
        return "addfunding-selector"
    }
}
</script>

<style>
.module-add_funding {
    padding: 1rem;
    background-color: rgb(175, 175, 175);
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    border-radius: 1rem;
}

.error {
    color: red;
}
.category {
    margin-bottom: 1rem;
    display: flex;
}
.category-name{
    flex-grow:1;
}

.currency-line {
    display: flex;
}
.select-category{
    padding-top: .5rem;
    padding-bottom: .5rem;
}

input {
    color: black;
}
select {
    color: black;
}
</style>