

import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import { PlanFunding } from "possibleme-db";
import { Prop, Watch } from "vue-property-decorator"
import { displayMoney } from "possibleme-db/lib/util/BigNum"

export default class ModelSupport extends FireComponent {
    @Prop()
    funding! :  {[key: string]: PlanFunding};

    sortSet : {[key: string]: PlanFunding} = {};

    displayAmount = displayMoney

    @Watch("funding")
    update(){
        this.sortSet = {}
        const list = Object.keys(this.funding).sort((a, b)=> {
            const aFund = this.funding[a];
            const bFund = this.funding[b];

            const great = aFund.name > bFund.name;
            if(great)
                return 1;
            return -1;
        });
        list.forEach(key => this.sortSet[key] = this.funding[key])
    }
    
    mounted(){
        this.update()
    }
   

    componentClass = () => "possible-model-funding"
    onUserReady(): void {
        return;
    }
    
}
