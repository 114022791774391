
import { Options } from "vue-class-component"
import ModelFunding from "@/components/possibleme/model/ModelFunding.vue"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { NDISPlan } from "possibleme-db";
import { Prop } from "vue-property-decorator"
import * as DateHelp from "possibleme-db/lib/util/DateHelper"
import {selectCustomPlan} from "@/scripts-possibleme/client/PossibleClient"
import {downloadUserURL} from "@/scripts-core/CloudDB"
import MeButton from "../helper/MeButton.vue"


export type OnEditPlan = (plan : CloudObject<NDISPlan>)=>void

@Options({
    components :{
        ModelFunding,
        MeButton
    }
})

export default class ModelProvider extends FireComponent {
    @Prop()
    plan! : CloudObject<NDISPlan>
    dateHelper = DateHelp

    @Prop()
    edit! : OnEditPlan

   

    componentClass = () => "possible-model-plan"
    onUserReady(uid: string|null): void {
        return;
    }
    onSelect(){
        selectCustomPlan(this.plan);
        this.sendAlert("New Plan Selected", `You are now viewing NDIS Plan:\n'${this.plan.value(this).name}'`)
    
        this.$router.push( { path : this.$route.path , query : {
            select :  this.plan.id()
        } })
    }
    async onFile(){
        const filename = this.plan.value(this).filename;

        try {
            if(filename){
                const fileUrl = await downloadUserURL(filename);
                window.open(fileUrl);
     
            }
        }catch(e){
            console.error(e);
        }
    }
    onReportPlan(){
        const path = this.$router.resolve({
            path : "/report/plan",
            query : {
                planId : this.plan.id()
            }
        })
        window.open(path.href, '_blank');
    }
    onReportBudget(){
        const path = this.$router.resolve({
            path : "/report/budget",
            query : {
                planId : this.plan.id()
            }
        })
        window.open(path.href, '_blank');
    }

    onEdit(){
        this.edit(this.plan);
    }

    click(){
        return;
    }
    getFilename(){
        let filename = this.plan.value(this).filename
        if(filename === "" || filename === undefined || filename === null)
            filename = "n/a"
        return filename;
    }
    getDays(){

        const now = Date.now();
        const end = this.plan.value(this).dateEnd;
        const start = this.plan.value(this).dateStart;
        if(end < now)
            return ""
        const delta = Math.floor( (end - now) /  86400000);
        return `${delta} Days Left`
    }
}
